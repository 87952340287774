<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col><div>Ujian</div></b-col>
            <b-col>
              <div align="right">
                <b-button
                  variant="outline-warning"
                  v-b-modal.modal-sm
                  :to="{ name: `admin-kategori-ujian` }"
                  class="btn-icon mr-1"
                >
                  <feather-icon icon="ListIcon" class="mr-25" />Kategori Ujian</b-button
                >
                <b-button
                  v-if="allowCreate()"
                  variant="outline-primary"
                  v-b-modal.modal-sm
                  class="btn-icon"
                  :to="{ path: 'ujian-admin/a' }"
                >
                  <!-- @click="ModalShow()" -->
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                >
              </div>
              <b-modal v-model="Modal" id="modal-tambah" centered :title="titleModal">
                <b-card-text>
                  <b-form>
                    <b-form-group label="Kategori" label-for="kategori">
                      <b-form-select v-model="form.type_ujian" :options="optkatujian" />
                    </b-form-group>
                    <b-form-group label="Kategori Paket" label-for="kategori">
                      <b-form-select
                        v-model="form.category_paket_id"
                        :options="optPaket"
                      />
                    </b-form-group>
                    <b-form-group label="Mata Pelajaran" label-for="mapel">
                      <b-form-select v-model="form.mapel_id" :options="optmapel" />
                    </b-form-group>
                    <b-form-group label="Nama Ujian" label-for="name">
                      <b-form-input id="name" v-model="form.name" placeholder="name" />
                    </b-form-group>
                    <b-form-group label="Nama Ujian (Singkat)" label-for="shortname">
                      <b-form-input
                        id="shortname"
                        v-model="form.shortname"
                        placeholder="shortname"
                      />
                    </b-form-group>

                    <b-form-group label="Acak Soal" label-for="question">
                      <b-form-select
                        v-model="form.shuffle_question"
                        :options="optquestion"
                      />
                    </b-form-group>
                    <b-form-group label="Tampilkan Hasil" label-for="show_result">
                      <b-form-select v-model="form.show_result" :options="optresult" />
                    </b-form-group>
                    <b-form-group label="Instruksi" label-for="instruction">
                      <quill-editor
                        id="Konten"
                        v-model="form.instruction"
                        :options="editor_options"
                      >
                      </quill-editor>
                    </b-form-group>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click.prevent="tutupModal"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="3" class="">
              <label>Pilih Mapel</label>
              <v-select
                class="select-size-sm w-full"
                label="text"
                v-model="filterMapel"
                @input="getData()"
                :clearable="true"
                :options="optmapel"
              />
            </b-col>

            <b-col md="3" sm="3" class="">
              <label>Pilih Kategori Paket</label>
              <v-select
                label="text"
                v-model="filterPaket"
                class="select-size-sm w-full"
                @input="getData()"
                :clearable="true"
                :options="optPaket"
              />
            </b-col>
            <b-col md="3" sm="3" class="">
              <label>Pilih Kategori</label>
              <v-select
                label="text"
                v-model="filterTipe"
                class="select-size-sm w-full"
                @input="getData()"
                :clearable="true"
                :options="optkatujian"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search......"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="ujiandata"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item :to="{ path: `ujian-admin/detail/${row.item.id}` }">
                      <feather-icon icon="SettingsIcon" class="mr-50" />
                      <span>Detail dan Pilih Soal</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="allowUpdate()" @click="ModalUbah(row.item)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="allowDelete()" @click="ModalHapus(row.item)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-- <b-button variant="outline-info" class="btn-icon" @click="ModalUbah(row.item)"> <feather-icon icon="EditIcon" /></b-button>
                  <b-button variant="outline-danger" class="btn-icon ml-1" @click="ModalHapus(row.item)"> <feather-icon icon="TrashIcon" /></b-button> -->
                </template>
                <!-- A custom formatted column -->
                <template #cell(name)="data">
                  {{ data.item.name }}
                  <br />
                  <b-badge variant="primary">
                    {{ data.item.shortname }}
                  </b-badge>
                </template>
                <template #cell(start_time)="data">
                  {{ humanDateTime(data.item.start_time) }}
                </template>
                <template #cell(end_time)="data">
                  {{ humanDateTime(data.item.end_time) }}
                </template>
                <template #cell(timer)="data">
                  {{ data.item.timer + " menit" }}
                </template>

                <!-- A virtual composite column -->
                <template #cell(soal_ids)="data">
                  {{ data.item.soal }}
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <!-- <b-pagination
          v-if="record !== 0"
          :total="Math.ceil((total * 1) / itemsPerPage)"
          v-model="currentPage"
          @change="staf()"
        /> -->
              <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
          :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    quillEditor,
    vSelect,
    flatPickr,
    BDropdownItem,
    BDropdown,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      filterPaket: null,
      optPaket: [],
      filterMapel: null,
      // optMapel: [],
      filterTipe: null,
      optTipe: [],
      id: null,
      activeAction: null,
      label: "Simpan",
      optquestion: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      optresult: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      //Data
      optmapel: [],
      optkatujian: [],
      ujiandata: [],
      form: {
        id: null,
        user_id: null,
        category_paket_id: null,
        name: null,
        shortname: null,
        soal_ids: null,
        show_result: null,
        instruction: null,
        shuffle_question: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Nama Ujian" },
        // { key: "mapel.nama_mapel", label: "Mata Pelajaran" },
        { key: "category_paket.name", label: "Kategori Paket" },
        { key: "category.nama_ujian", label: "Kategori", sortable: true },
        // { key: "soal_ids", label: "Jumlah Soal" },
        // { key: "end_time", label: "Tanggal & Waktu Akhir" },
        // { key: "min_nilai", label: "Minimal Nilai" },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optWarna: [
        { value: "primary", text: "primary" },
        { value: "info", text: "info" },
        { value: "secondary", text: "secondary" },
        { value: "warning", text: "warning" },
        { value: "success", text: "success" },
        { value: "light-primary", text: "light-primary" },
        { value: "light-info", text: "light-info" },
        { value: "light-secondary", text: "light-secondary" },
        { value: "light-warning", text: "light-warning" },
        { value: "light-success", text: "light-success" },
      ],
      optFilter: [
        { id: "name", value: "Kategori" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getData();
    },
    resetForm() {
      this.form = {
        id: null,
        type_ujian: null,
        name: null,
        shortname: null,
        soal_ids: null,
        start_time: null,
        end_time: null,
        timer: null,
        min_nilai: null,
      };
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Ujian";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.form.type_ujian = item.category.id;
      this.form.mapel_id = item.mapel.id;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Ujian";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("adminujian/save", [data])
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        type_ujian: this.form.type_ujian,
        user_id: this.user.id,
        category_paket_id: this.form.category_paket_id,
        mapel_id: this.form.mapel_id,
        name: this.form.name,
        shortname: this.form.shortname,
        soal_ids: this.form.soal_ids,
        show_result: this.form.show_result,
        shuffle_question: this.form.shuffle_question,
        instruction: this.form.instruction,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("adminujian/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.resetForm();
          this.Modal = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Ujian berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getData() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        category_paket_id: this.filterPaket != null ? this.filterPaket.id : null,
        mapel_id: this.filterMapel != null ? this.filterMapel.id : null,
        type_ujian: this.filterTipe != null ? this.filterTipe.id : null,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("adminujian/index", payload)
        .then((response) => {
          this.loading = false;
          let ujiandata = response.data.data;
          this.ujiandata = ujiandata;
          this.totalRows = this.ujiandata.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getDataKategoriUjian() {
      // this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("adminujian/indexKategoriUjian", payload)
        .then((response) => {
          // this.loading = false;
          let optkatujian = response.data.data;
          optkatujian.map((item) => {
            item.value = item.id;
            item.text = item.nama_ujian;
          });
          this.optkatujian = optkatujian;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },

    async getDataMapel() {
      // this.loading = true;
      const payload = {};
      this.$store
        .dispatch("mapel/index", payload)
        .then((response) => {
          // this.loading = false;
          let optmapel = response.data.data;
          optmapel.map((item) => {
            item.value = item.id;
            item.text = item.nama_mapel;
          });
          this.optmapel = optmapel;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getDataKategoriPaket() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let optPaket = response.data.data;
          optPaket.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optPaket = optPaket;
          // this.totalRows = this.katsoalData.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {},
  async mounted() {
    this.getData();
    this.getDataKategoriUjian();
    this.getDataMapel();
    this.getDataKategoriPaket();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
